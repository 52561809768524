// LearnCard.js

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

const DataScards = () => {
  const courses = [
    {
      id: 1,
      title: 'Fundamentals Of Data Science',
      image: 'images/datascience.png',
      details: 'Details about Data Science Course ',
      // path: '/html-course', // Define the path for the HTML course
      courseMode:'online',
      limitedSlots:"Limited slots left"

    },
    {
        id: 2,
        title: 'Fundamentals Of Machine Learning',
        image: 'images/ml.png',
        details: 'Details about Machine Learning Course',
        // path: '/javascript-course', // Define the path for the JavaScript course
      },
    {
      id: 3,
      title: 'Fundamentals Of Power Bi  Tool',
      image: 'images/powerbi.png',
      details: 'Details about Power Bi Course',
      // path: '/javascript-course', // Define the path for the JavaScript course
    },
    {
      id: 4,
      title: 'Fundamentals Of Data Analysis',
      image: 'images/dataanalysis.png',

      details: 'Details about Data Analysis Course',
      // path: '/flask-course', // Define the path for the Flask course
    },
  
    // Add more courses as needed
  ];

  return (
    
    <div className="row mt-5">
    {courses.map((course) => (
      <div key={course.id} className="col-lg-3 col-md-6 col-12">
        <div className="telugu-course-cards">
          <img src={course.image} alt={course.title} className="cardImage" />
          <div className="cardBody">
            <h5>{course.title}</h5>
            <p className="Text">{course.details}</p>
          </div>
          <div className="cardFooter d-flex justify-content-between align-items-end">
            
              <div className="courseMode ">Recorded/Live</div>
              <div className="limitedSlots">Limited Slots </div>
           
           
          </div>
          <div className='enroll-btn'>
          <Link to={course.path} style={{ margin: 0 }}>
              <button className="btn btn-enroll">Enroll Now</button>
            </Link>
            </div>
        </div>
      </div>
    ))}
  </div>
  );
};
// const DataScards = () => {
//   const courses = [
//     {
//       id: 1,
//       title: 'Introduction to Data Science',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about HTML Course Define the path for the HTML course Define the path for the HTMLcourse.... ',
//       path: '/html-course', // Define the path for the HTML course
//     },
//     {
//       id: 2,
//       title: 'Data Analysis with Python',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about CSS Course',
//       path: '/css-course', // Define the path for the CSS course
//     },
//     {
//       id: 3,
//       title: 'Statistical Methods for Data Science',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about JavaScript Course',
//       path: '/javascript-course', // Define the path for the JavaScript course
//     },
//     {
//       id: 4,
//       title: 'Machine Learning Fundamentals',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about Flask Course',
//       path: '/flask-course', // Define the path for the Flask course
//     },
//     {
//       id: 1,
//       title: 'Data Visualization Techniques',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about HTML Course Define the path for the HTML course Define the path for the HTML course ',
//       path: '/html-course', // Define the path for the HTML course
//     },
//     {
//       id: 1,
//       title: 'Deep Learning and Neural Networks',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about HTML Course Define the path for the HTML course Define the path for the HTML course ',
//       path: '/html-course', // Define the path for the HTML course
//     },
//     {
//       id: 1,
//       title: 'Natural Language Processing (NLP) for Data Science',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about HTML Course Define the path for the HTML course Define the path for the HTML course ',
//       path: '/html-course', // Define the path for the HTML course
//     },
//     {
//       id: 1,
//       title: 'Predictive Modeling and Regression Analysis',
//       image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
//       details: 'Details about HTML Course Define the path for the HTML course Define the path for the HTML course ',
//       path: '/html-course', // Define the path for the HTML course
//     },
   
//     // Add more courses as needed
//   ];

//   return (
//     <div className="row mt-5 ">
//       {courses.map((course) => (
//         <div key={course.id} className="col-lg-3 col-md-6 col-12">
//           <div className="learn-card">
//             <img src={course.image} alt={course.title} className="cardImage" />
//             <div className="cardBody">
//               <h5>{course.title}</h5>
//               <p className="Text">{course.details}</p>
//             </div>
//             <div className="cardFooter">
//               {/* Use Link to navigate to the specified path when clicking on the button */}
//               <p className="viewButton">
//                 View Course
//               </p>
//               <Link to={course.path}  style={{ margin: 0 }}>
               
//               <span className="arrowSymbol">&#62;</span>
//               </Link>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

export default DataScards;
