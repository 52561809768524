import React from 'react';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';

const Testimonials = () => {
  const testimonialsData = [
    {
      name: 'Venu j',
      designation: 'Frontend Developer',
      quote:
        'I made back the purchase price in just 48 hours! Thank you for making it painless, pleasant. The service was excellent. I will refer everyone I know.',
      imageSrc:
        'images/venu.jpg',
    },
    {
      name: 'Robert Garrison',
      designation: 'Android Developer',
      quote:
        'I made back the purchase price in just 48 hours! Thank you for making it painless, pleasant. The service was excellent. I will refer everyone I know.',
      imageSrc:
        'https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80',
    },
    {
      name: 'Robert Garrison',
      designation: 'Android Developer',
      quote:
        'I made back the purchase price in just 48 hours! Thank you for making it painless, pleasant. The service was excellent. I will refer everyone I know.',
      imageSrc:
        'https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80',
    },
    {
      name: 'Robert Garrison',
      designation: 'Android Developer',
      quote:
        'I made back the purchase price in just 48 hours! Thank you for making it painless, pleasant. The service was excellent. I will refer everyone I know.',
      imageSrc:
        'https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80',
    },
    {
      name: 'Robert Garrison',
      designation: 'Android Developer',
      quote:
        'I made back the purchase price in just 48 hours! Thank you for making it painless, pleasant. The service was excellent. I will refer everyone I know.',
      imageSrc:
        'https://images.unsplash.com/photo-1557862921-37829c790f19?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80',
    },
    // Add more testimonials as needed
  ];

  const renderCards = (cardsPerCarouselItem) => {
    const renderedCards = [];

    for (let i = 0; i < testimonialsData.length; i += cardsPerCarouselItem) {
      const cardsInCurrentItem = testimonialsData.slice(i, i + cardsPerCarouselItem);

      renderedCards.push(
        <Carousel.Item key={i}>
          <Row className="justify-content-center testimonial">
            {cardsInCurrentItem.map((testimonial, index) => (
              <Col lg="4" key={index}>
                <Card className="test-card">
                  <Card.Body>
                    <div className="face front-face">
                      <img
                        src={testimonial.imageSrc}
                        alt=""
                        className="profile"
                      />
                      <div className="pt-3 text-uppercase name">
                        {testimonial.name}
                      </div>
                      <div className="designation">
                        {testimonial.designation}
                      </div>
                    </div>
                    <div className="face back-face">
                      <span className="fas fa-quote-left"></span>
                      <div className="testimonial-text">{testimonial.quote}</div>
                      <span className="fas fa-quote-right"></span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      );
    }

    return renderedCards;
  };

  return (
    <Container className="mt-5 ">
    {/* Show the first Carousel only on mobile devices */}
    <Carousel className="d-sm-block d-md-none">
      {renderCards(1)}
    </Carousel>
  
    {/* Show three cards per carousel item on large screens */}
    <Carousel className="d-none d-lg-block">
      {renderCards(3)}
    </Carousel>
  
    {/* Show the second Carousel only on large devices */}
    <Carousel className="d-none d-md-block d-lg-none">
      {renderCards(3)} {/* You can adjust the number of cards as needed */}
    </Carousel>
  </Container>
  
  );
};

export default Testimonials;
