import React, { useState } from 'react';
import { toast ,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    number: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_API)
    try{
        const res= await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/register`,{
            name: formData.name,
      email: formData.email,
      password: formData.password,
      number: formData.number,
        })
   
    if(res.data.success)
    {
        toast.success(res.data.message);

    }
}
catch(error)
{
    console.log(error)
}
   
    console.log('Form submitted:', formData);
  
    // You can send the data to your server or perform any other actions
  };

  return (
    <div className="registration-form">
      <h2>Registration Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Phone Number:
          <input
            type="tel"
            name="number"
            pattern="[0-9]{10}"
            value={formData.number}
            onChange={handleChange}
            required
          />
          <small>Enter a 10-digit phone number</small>
        </label>

        <button type="submit">Register</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Register;
