import React , { useState, useEffect } from 'react'
import Layout from '../Components/Layout/Layout'
import Telugu from '../Components/telugu/Telugucourse'
import Web from '../Components/Layout/webdevelopment/WebDevCards'

const TypewriterText = ({ texts }) => {
  const [displayText, setDisplayText] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [isErasing, setIsErasing] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (texts.length === 0) return; // Exit early if texts is an empty array

      const currentText = texts[textIndex];
      if (!currentText) return; // Exit if currentText is undefined or null

      if (!isErasing && charIndex < currentText.length) {
        setDisplayText(prevText => prevText + currentText.charAt(charIndex));
        setCharIndex(prevIndex => prevIndex + 1);
      } else if (isErasing && charIndex > 0) {
        setDisplayText(prevText => prevText.slice(0, -1));
        setCharIndex(prevIndex => prevIndex - 1);
      } else {
        // setIsErasing(!isErasing);
        // if (!isErasing) {
        //   setTextIndex(prevIndex => (prevIndex + 1) % texts.length); // Move to the next text
        // }
        // setCharIndex(isErasing ? 0 : currentText.length); // Reset charIndex
        setTimeout(() => {
          setIsErasing(!isErasing);
          if (!isErasing) {
            setTextIndex(prevIndex => (prevIndex + 1) % texts.length); // Move to the next text
          }
          setCharIndex(isErasing ? 0 : currentText.length); // Reset charIndex
        }, 150);
      }
    },150);

    return () => clearTimeout(timeout);
  }, [charIndex, textIndex, isErasing, texts]);

  return (
    <h2 style={{ color: '#354c99' }}>
      <span className="typewriter-text">{'<' + displayText + 
      '>'}</span>
      {/* <span className="typewriter-cursor">|</span> */}
    </h2>
  );
};

const HomePage = () => {

  // course starts
  const [activeSection, setActiveSection] = useState('techstack');

  const renderSectionContent = () => {
    switch (activeSection) {
      case 'techstack':
        return (
          <div className="row">
           <Web/>
          </div>
        );
      case 'telugustack':
        return (
          <div className="row">
         <Telugu/>
          </div>
        );
      default:
        return null;
    }
  };
  // course endds

  
  const [bgIndex, setBgIndex] = useState(0);
  const bgImages = ['url(images/dbg.png)', 'url(images/dbg1.png)','url(images/dbg2.png)'];

  useEffect(() => {
    const interval = setInterval(() => {
      setBgIndex(prevIndex => (prevIndex + 1) % bgImages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  
    
  return (
    <>
     <Layout title={'Vegrade-Home'}>
    
  {/* starting page  */}

  <div className="container my-4" style={{ 
      background: `${bgImages[bgIndex]} no-repeat center/cover`,
      // backgroundSize: "100%", // Set background size to cover entire container
      backgroundRepeat: "no-repeat",
      minHeight: "75vh"
    }}>
  <div className="row home  ">
    
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        {/* <h1><span className='learn-span'>Transform </span>Thrive <br></br> Conquer: Endless Education </h1> */}
        <h2 style={{color:"#354c99"}}>Learning with Vegrade made   </h2>
        <TypewriterText texts={["Intuitive", "Affordable", "Pratical"]} />
        <p>Explore diverse courses, from tech to creativity, and advance your career with tailored learning experiences!</p>
        <button className='Telugu-btn' >
          Start Learning
        </button>
   
   
  

    
        {/* <h3>Discover endless possibilities with Code Stump. We're more than a platform – we're a launchpad for your ambitions. Explore, learn, and thrive in the world of tech with us</h3> */}
      </div>
     
    </div>
    

    {/* Right side: Image */}
    <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
      src="images/home-img.png" 
        // src="images/home-main.png" // Replace with your image URL
        alt="Sample"
        style={{ height:'30rem',width:'100rem'}}
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>

{/* <div className='container mt-5 d-none d-lg-block'>
  <h3 className='text-center mt-5'>Trending Domains</h3>
  <div className='row top-domains mt-5'>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Web Development</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Mobile App Development</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Data Base Technologies</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>UI/UX Designing</p>
        </div>
    </div>
    

   
</div>
<div className='row top-domains top-domains-two ' style={{marginLeft:'10rem'}}>
<div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>AWS & Devops</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Data Science</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Cyber Security</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
       
        <div>
            <p>Explore more</p>
        </div>
        <div className='arrow-symbol'>
    <span className='arrow-symbol' >&#8593;</span>
</div>

    </div>
    </div>

</div> */}

<div className="container mt-5 home-courses">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Our Courses</h2>
        <a href="" className="nav-link">
          View all &gt;
        </a>
      </div>
      <div className="row mb-3">
        <div className="col-md-2 mb-3">
          <a
            href="#"
            className={`nav-link ${activeSection === 'techstack' ? 'active' : ''}`}
            onClick={() => setActiveSection('techstack')}
          >
            Tech Stack
          </a>
        </div>
        <div className="col-md-2 mb-3">
          <a
            href="#"
            className={`nav-link ${activeSection === 'telugustack' ? 'active' : ''}`}
            onClick={() => setActiveSection('telugustack')}
          >
            Telugu Stack
          </a>
        </div>
      </div>
      {renderSectionContent()}
    </div>

  {/* starting page ends */}
{/* ######## course structure or career  */}

  {/* course strcure  */}
  <div className="container mt-5 home">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Sessions</span> For Career Developing in Top-Technologies</p>
          <div className='line'><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        {/* Card 1 */}
        <div className="col-md-3 mb-3 mt-5 d-flex justify-content-center">
      <div className="cards cards1 ">
        
         
            <div className="circle1">
              
              <img src="/images/flutter-icon.png" alt="Logo" className="logo" />
            </div>
          <h5>Flutter </h5>
      

          
         <div>
         <p >
         Flutter streamlines mobile app development with a unified codebase, allowing developers to create dynamic & visually consistent applications for both iOS & Android platforms without context switching

          </p>
         </div>

          
          <div className="view-curriculum mt-3">
            <button >
              View Curriculum <span className='arrow'>&#x2192; </span>
            </button>
          </div>
        </div>
      {/* </div> */}
    </div>

        {/* Card 2 */}
        <div className="col-md-3 mb-3 mt-5 d-flex justify-content-center">
      <div className="cards cards2">
       
         
            <div className="circle2">
              
              <img src="/images/datascience-icon.png" alt="Logo" className="logo" />
            </div>
          <h5>Data Science </h5>
      

        
         <div>
         <p >
         Data Science unlocks valuable insights from data, driving informed decisions. It empowers businesses through predictive analysis and pattern discovery for actionable intelligence

          </p>
         </div>

        
            <button >
              View Curriculum <span className="arrow">&#x2192;</span>
            </button>
         
        </div>
    
    </div>

      
    <div className="col-md-3 mb-3 mt-5 d-flex justify-content-center">
      <div className="cards cards3">
     
         
            <div className="circle3">
              
              <img src="/images/mern-icon.png" alt="Logo" className="logo" />
            </div>
          <h5> MERN  Development </h5>
      

         <div>
         <p >
         MERN stack streamlines full-stack web development with MongoDB, Express.js, React.js, and Node.js, fostering seamless integration and JavaScript continuity across the stack
          </p>
         </div>

        
            <button >
              View Curriculum <span className="arrow">&#x2192;</span>
            </button>
       
        </div>
     
    </div>

        {/* Card 4 */}
        <div className="col-md-3 mb-3 mt-5 d-flex justify-content-center">
      <div className="cards cards4">
     
         
            <div className="circle4">
              
            <img src="/images/aws.png" alt="Logo" className="logo" />
            </div>
          <h5>Cloud(AWS)  </h5>
      

         <div>
         <p >
         AWS, Amazon's cloud platform, provides scalable and cost-effective solutions, empowering businesses to innovate and deploy applications with efficiency and reliability in the cloud </p>

        
         </div>

        
            <button >
              View Curriculum <span className="arrow">&#x2192;</span>
            </button>
       
        </div>
     
    </div>
    {/*  */}
       
      </div>
    </div>
    
    {/* strurcuter end */}

{/* #################course structure ends */}

{/* ##### career path */}

 {/* career path */}
 <div className='container  careerpath mt-5'>

<div className='title mt-5'>
  <p className='text-center' style={{fontSize:'2rem',fontWeight:'700',color:'#233e4b'}}>
  Strategic Roadmap to Propel Your Journey Towards <br></br> <span>Your Dream Career</span>"
  </p>

</div>
<div className='row mt-5 home'>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img  src='images/learrning.png'>

    </img>
    <h6 style={{color:"#363e92" }}>Embrace Continuous Learning:</h6>
    <p  style={{color:"#233e4b" ,fontFamily:'Arno Pro'}}>Understand that technology is dynamic, and ongoing education is essential.
Be open to learning new languages, tools, and methodologies as they emerge.</p>
  </div>
  </div>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img src='images/mentor.png'>

    </img>
    <h6 style={{color:"#363e92"}}>Unlock Accelerated Growth with Mentorship</h6>
    <p>Seek guidance from seasoned professionals through mentorship programs.
Leverage the experience of mentors to fast-track your learning journey. </p>
  </div>
  </div>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img src='images/Collaborate.png'>
   
  
    </img>
    <h6 style={{color:"#363e92"}}>  Collaborate and Contribute to Open Source:</h6>
    <p>Actively participate in open-source projects on platforms like GitHub.
Collaborate with experienced developers, showcase your skills </p>
  </div>
  </div>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img src='images/skills.png'>

    </img>
    <h6 style={{color:"#363e92"}}>Craft a Well-Rounded Skill Set</h6>
    <p>Develop not only technical expertise but also essential soft skills.
Enhance communication, teamwork, and problem-solving abilities for a comprehensive skill set </p>
  </div>
  </div>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img src='images/challenge.png'>

    </img>
    <h6 style={{color:"#363e92"}}>Challenge Yourself</h6>
   
    <p>Participate in hackathons and coding competitions to challenge yourself and apply your skills under time constraints. </p>
  </div>
  </div>
  <div  className='col-md-4 mb-3'>
  <div className='career-cards'>
    <img src='images/iterate.png'>

    </img>
  
    <h6 style={{color:"#363e92"}}>  Reflect and Iterate</h6>
    <p>Periodically assess your progress, identify areas for improvement, and set new goals. Continuous reflection and iteration are key to refining your skills </p>
  </div>
  </div>
</div>

</div>



{/* career path ends */}

{/* #### career path ends */}

{/* # team code */}
 {/* top team code */}

 <div className='container top-team mt-5 home-team'>
  <h2 style={{color:'#233e4b' ,fontWeight:'700'}} className='text-center  '>Our <span>top notch teams</span> involved in <br></br>
helping you learn programming, not just coding.</h2>




<div className='team-members mt-5'>


  <img src='images/team.png'></img>
</div>

</div>


{/* top team code ends */}

{/* execution structure  */}
{/*  structure of excution */}
<div className="container mt-5 home-courses">
  <h3 className='text-center' style={{color:'#233e4b',fontWeight:'700'}}>Unlock Lucrative Paths at Vegrade's: Elevate Your Career in <br></br> <span>4.0 Technology</span></h3>
  <div className="row mt-5">
    {/* Flow 1 */}
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/code.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Code Foundations<br></br> Learn Syntax  </p>
    
      </div>
      <div >
   
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/tech.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Tech Mastery<br></br>Master Technologies  </p>
    
      </div>
      <div >
      
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/portfolio.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Digital Showcase<br></br> Skills  </p>
    
      </div>
      <div >
    
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/innovation.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Innovation Focus<br></br>Explore Innovations </p>
    
      </div>
      <div >

    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/project.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Impactful Projects<br></br>Develop Projects </p>
    
      </div>
      <div >
  
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/support.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Tech Networking<br></br>Seek Guidance</p>
    
      </div>
      <div >
      {/* <span>&#x2192;</span> */}
    </div>
    </div>

   
  </div>
</div>

   
    {/* ececution struture endds */}

{/* execution structure ends */}
 {/* mentor comunity  */}
 <div className='container mt-5  home-courses'>
      <div className='community mt-5 '>
        <h2 style={{fontWeight:'700'}}> <span>Mentor Community</span>  From</h2>
        <p className='mt-3'style={{fontSize:'1.1rem',fontFamily:'rubik',fontWeight:'500'}}> Our mentor community strongly believes in the power of sharing! <br></br> We collaborate with professionals from great companies to guide you on this exciting journey.</p>
        {/* <img src='https://nxtwave.imgix.net/ccbp-website/Home/Mentor+Community/mentor-community-companies.png?100px=format,compress&q=80'></img> */}
      </div>
      <div className='companies row mt-5 '>


  <div className='col-md-12 mb-3'>
    <img
      className="img-fluid"
      src='images/mentor-community.png'
      alt="Company Logo"
    />
  </div>
  
</div>
    </div>
    <div className='container home'>
      <div className='companies row mt-5 '>
        <h2 className='' style={{fontWeight:'700'}}><span>Our Students</span> From</h2>

        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center '>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse3.mm.bing.net/th?id=OIP.2H1OQJoWQuqEKrD-Z8_X_gAAAA&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center'>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse4.mm.bing.net/th?id=OIP.aRz8WvUVu_oBjVWns3LgqgHaHX&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center'>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse4.mm.bing.net/th?id=OIP.6n5Zn7htmX-jn0P4O3AfqwAAAA&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center'>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse2.mm.bing.net/th?id=OIP.bQRZGJqCA__uAeR8eoFwuwAAAA&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center'>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse3.mm.bing.net/th?id=OIP.c2IXYgRdSHr56DWt-_zfXAAAAA&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
        <div className='col-md-6 col-lg-2 mb-6 mt-5 text-center'>
          <img
            className="img-fluid"
            style={{ maxWidth: '100px', height: '100px' }}
            src='https://tse4.mm.bing.net/th?id=OIP.GJsi3r8E8Gn6LAQmJkZZLwAAAA&pid=Api&P=0&h=220'
            alt="Company Logo"
          />
        </div>
      </div>
    </div>
    {/* mentor community ends */}


{/* ###team code ends */}

  {/* testimonials */}
  
 
  <div className='container mt-5 home'>
    <div className='row impact'>
  <div className='col-6'>
    <img src='images/global.png'></img>
    <h1  style={{fontWeight:'700',color:'#233e4b'}}><span>Impact At </span> .. Scale</h1>
    <p style={{color:'#233e4b',fontWeight:'600'}}>
      making Education affordable and accessible across the Globe
    </p>
    <div className='row'>
      <div className='col-6'>
<h1 style={{fontWeight:'700',color:'#233e4b'}}>1000+  <br></br>  </h1>
<p style={{color:'#233e4b',fontWeight:'600'}}> Satisfied Students</p>
      </div>
      <div className='col-6'>
<h1 style={{fontWeight:'700',color:'#233e4b'}}>20+   <br></br>  </h1>
<p style={{color:'#233e4b',fontWeight:'600'}}>Top Notch Mentor's</p>
      </div>
      <div className='col-6'>
<h1 style={{fontWeight:'700',color:'#233e4b'}}> 10+   <br></br>   </h1>
<p style={{color:'#233e4b',fontWeight:'600'}}>hackathons</p>
      </div>
      <div className='col-6'>
<h1 style={{fontWeight:'700',color:'#233e4b'}}> 20+  <br></br>  </h1>
<p style={{color:'#233e4b',fontWeight:'600'}} >Live Projects</p>
      </div>
    </div>
  </div>
  <div className='col-6 right'>.
  
    <img src='https://tse1.mm.bing.net/th?id=OIP.U4Q9nMxTz9bPqkVncRqS2wHaFj&pid=Api&P=0&h=220'></img>
  </div>
</div>

    </div>






 
    

     </Layout>
  
     </>
  )
}

export default HomePage