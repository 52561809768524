// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';

// const CoursePage = () => {
//   const { courseName } = useParams();
//   const [videos, setVideos] = useState([]);
//   const [selectedVideo, setSelectedVideo] = useState(null);

//   useEffect(() => {
//     const fetchVideos = async () => {
//       try {
//         const response = await axios.get(`http://localhost:3001/videos/${courseName}`);
//         setVideos(response.data);
//       } catch (error) {
//         console.error("Error fetching videos:", error.message);
//       }
//     };
//     fetchVideos();
//   }, [courseName]);

//   const playVideo = (video) => {
//     setSelectedVideo(video);
//   };

//   return (
//     <div className="course-page-container">
//       <div className="titles-container">
//         <h2>Titles</h2>
//         <ul>
//           {videos.map((video, index) => (
//             <li key={index} onClick={() => playVideo(video)}>
//               {video}
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="video-container">
//         <h2>Videos List</h2>
//         {selectedVideo && (
//           <div>
//             <video controls controlsList="nodownload" disablePictureInPicture>
//               <source src={`http://localhost:3001/video/${courseName}/${selectedVideo}`} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CoursePage;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";

const CoursePage = () => {
  const { courseName } = useParams();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      const user = auth.currentUser;

      if (!user) {
        navigate("/", { replace: true }); // Redirect to login page if user not logged in
        return;
      }

      try {
        // Fetch videos for the course using the courseName parameter
        const response = await fetch(
          `https://vegrade-classes.s3.ap-south-1.amazonaws.com/${courseName}/video.json`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch");
        }

        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error(`Error fetching videos for ${courseName}:`, error.message);
      }
    };

    fetchVideos();
  }, [courseName, navigate]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <div style={{ maxWidth: "100%", overflow: "hidden" 
    
    }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" , color: "white",
    backgroundColor: "blue",
    height: "4rem",padding:'20px'}}>
        <h4>{courseName} Videos</h4>
        <div style={{ marginLeft: "auto", marginRight: "20px" }}>
          <button
            onClick={handleLogout}
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Logout
          </button>
          {/* Logout button */}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 20%", borderRight: "1px solid #ccc", paddingRight: "10px", overflowY: "auto" }}>
          <h4>Course Modules</h4>
          <ul style={{ listStyleType: "none", padding:'20px', paddingLeft: "20px" }}>
            {videos.map((video, index) => (
              <li
                key={index}
                onClick={() => handleVideoClick(video)}
                style={{
                  cursor: "pointer",
                  marginBottom: "10px",
                  color: selectedVideo && selectedVideo.title === video.title ? "blue" : "inherit",
                }}
              >
          {video.title}
              </li>
            ))}
          </ul>
        </div>
        <div style={{ flex: "2 1 70%", paddingLeft: "10px" }}>
          {selectedVideo ? (
            <div style={{ width: "100%", border: "1px solid #ccc", padding: "10px", height: "80vh" }}>
              <video
                controls
                controlsList="nodownload"
                disablePictureInPicture
                src={selectedVideo.url}
                type="video/mp4"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ) : (
            <p>No video selected</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
