// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { auth, firestore } from "../../firebase-config";
// import { doc, getDoc } from "firebase/firestore";

// const Dashboard = () => {
//   const [userUID, setUserUID] = useState(null);
//   const [userEmail, setUserEmail] = useState(null);
//   const [userCourses, setUserCourses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [videos, setVideos] = useState([]);
//   const [activeAccordion, setActiveAccordion] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (user) {
//       setUserEmail(user.email);
//       setUserUID(user.uid);
//       // Fetch courses from Firestore based on UID
//       const fetchUserCourses = async () => {
//         try {
//           const userCoursesDocRef = doc(firestore, "courses", userUID);
//           const userCoursesSnapshot = await getDoc(userCoursesDocRef);
//           if (userCoursesSnapshot.exists()) {
//             const userData = userCoursesSnapshot.data();
//             const enrolledCourses = Object.keys(userData).filter(
//               (key) => userData[key] === true
//             );
//             setUserCourses(enrolledCourses);
//           }
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching user courses:", error.message);
//           setLoading(false);
//         }
//       };
//       fetchUserCourses();
//     }
//   }, [userUID]);

//   const handleLogout = async () => {
//     try {
//       await auth.signOut();
//       navigate("/", { replace: true });
//     } catch (error) {
//       console.error("Error logging out:", error.message);
//     }
//   };

//   const handleCourseClick = async (courseName) => {
//     try {
//       const response = await fetch(
//         `https://vegrade-classes.s3.ap-south-1.amazonaws.com/${courseName}/video.json`,
//         {
//           method: "GET",
//         }
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch");
//       }
//       const data = await response.json();
//       setVideos(data);
//     } catch (error) {
//       console.error(`Error fetching videos for ${courseName}:`, error.message);
//     }
//   };

//   const handleAccordionToggle = (courseName) => {
//     setActiveAccordion(activeAccordion === courseName ? null : courseName);
//   };

//   return (
//     <div className="dashboard-container">
//       <div className="section-1">
//         <button onClick={handleCourseClick("Vegrade")}>Vegrade</button>
//         <div className="uid-logout">
//           <span>
//             {userUID && ` ${userUID}`}
//           </span>
//           <button onClick={handleLogout}>Logout</button>
//         </div>
//       </div>
//       <div className="section-2">
//         <div className="courses-list">
//           <h3>Courses List</h3>
//           {userCourses.map((course) => (
//             <div key={course} className="accordion">
//               <div
//                 className="accordion-header"
//                 onClick={() => handleAccordionToggle(course)}
//               >
//                 <h4>{course}</h4>
//                 <span>{activeAccordion === course ? "-" : "+"}</span>
//               </div>
//               {activeAccordion === course && (
//                 <div className="accordion-content">
//                   <button onClick={() => handleCourseClick(course)}>
//                     View Videos
//                   </button>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//         <div className="video-player">
//           <h3>Videos List</h3>
//           {videos.length > 0 ? (
//             <div>
//               <ul>
//                 {videos.map((video, index) => (
//                   <li key={index}>{video.title}</li>
//                 ))}
//               </ul>
//               <div className="video-container">
//                 <video
//                   controls
//                   controlsList="nodownload"
//                   disablePictureInPicture
//                 >
//                   <source src={videos[0]?.url} type="video/mp4" />
//                   Your browser does not support the video tag.
//                 </video>
//               </div>
//             </div>
//           ) : (
//             <p>No videos found.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { auth, firestore } from "../../firebase-config";
// import { doc, getDoc } from "firebase/firestore";

// const Dashboard = () => {
//   const [userEmail, setUserEmail] = useState(null);
//   const [userCourses, setUserCourses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const navigate = useNavigate();

//   const handleSettingsClick = () => {
//     // Add your settings functionality here
//   };

//   const toggleDropdown = () => {
//     setShowDropdown(!showDropdown);
//   };

//   useEffect(() => {
//     const user = auth.currentUser;

//     if (user) {
//       setUserEmail(user.email);

      
//       const fetchUserCourses = async () => {
//         try {
//           const userCoursesDocRef = doc(firestore, "courses", user.uid);
//           const userCoursesSnapshot = await getDoc(userCoursesDocRef);

//           if (userCoursesSnapshot.exists()) {
//             const userData = userCoursesSnapshot.data();
//             const enrolledCourses = Object.keys(userData).filter(
//               (key) => userData[key] === true
//             );

//             setUserCourses(enrolledCourses);
//           }
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching user courses:", error.message);
//           setLoading(false);
//         }
//       };

//       fetchUserCourses();
//     }
//   }, []);

//   const handleLogout = async () => {
//     try {
//       await auth.signOut();
//       navigate("/", { replace: true });
//     } catch (error) {
//       console.error("Error logging out:", error.message);
//     }
//   };

//   const handleCourseClick = (courseName) => {
//     navigate(`/courses/${courseName}`);
//   };

//   return (
//     <div className="">
//       <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" ,color:'white',backgroundColor:"blue",height:'4rem'}}>
//         <h3>Dashboard</h3> {/* Dashboard title */}
//         <div style={{ marginLeft: "auto", marginRight: "20px" }}>
//           <button onClick={handleLogout} style={{ backgroundColor: "red", color: "white", padding: "8px 16px", border: "none", borderRadius: "4px", cursor: "pointer" }}>
//             Logout
//           </button> {/* Logout button */}
//         </div>
//       </div>
//       <div className="container" style={{padding:"20px 20px"}}>
//         {userEmail && <p>User: {userEmail}</p>}
//         <h3>Courses Enrolled In</h3>
//         {loading ? (
//           <p>Loading user courses...</p>
//         ) : (
//           <ul style={{ listStyleType: "none", padding: 0, marginLeft: "20px" }}>
//             {userCourses.map((course) => (
//               <li
//                 key={course}
//                 onClick={() => handleCourseClick(course)}
//                 style={{ cursor: "pointer", marginBottom: "8px", color: "black",height:'4rem',width:'4rem',background:'red' }}
//                 onMouseEnter={(e) => e.target.style.color = "blue"}
//                 onMouseLeave={(e) => e.target.style.color = "black"}
//               >
//                 {course}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>

//   );
// };

// export default Dashboard;
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth, firestore } from "../../firebase-config";
import { doc, getDoc } from "firebase/firestore";

const Dashboard = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [userCourses, setUserCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    // Add your settings functionality here
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email);
      const fetchUserCourses = async () => {
        try {
          const userCoursesDocRef = doc(firestore, "courses", user.uid);
          const userCoursesSnapshot = await getDoc(userCoursesDocRef);
          if (userCoursesSnapshot.exists()) {
            const userData = userCoursesSnapshot.data();
            const enrolledCourses = Object.keys(userData).filter(
              (key) => userData[key] === true
            );
            setUserCourses(enrolledCourses);
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user courses:", error.message);
          setLoading(false);
        }
      };
      fetchUserCourses();
    }
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  const handleCourseClick = (courseName) => {
    navigate(`/courses/${courseName}`);
  };

  return (
    <div className="">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          backgroundColor: "blue",
          height: "4rem",
          padding:'20px'
        }}
      >
        <h3>Dashboard</h3>
        {/* Dashboard title */}
        <div style={{ marginLeft: "auto", marginRight: "20px" }}>
          <button
            onClick={handleLogout}
            style={{
              backgroundColor: "red",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Logout
          </button>
          {/* Logout button */}
        </div>
      </div>
      <div className="container" style={{ padding: "20px 20px" }}>
        {userEmail && <p>User: {userEmail}</p>}
        <h3>Courses Enrolled In</h3>
        {loading ? (
          <p>Loading user courses...</p>
        ) : (
          <div className="course-card-container">
            {userCourses.map((course) => (
              <Link
                key={course}
                to={`/courses/${course}`}
                style={{ textDecoration: "none" }}
              >
                <div className="course-card">
                  {/* <div className="course-image">
                    <img src={`/images/${course}.jpg`} alt={course} />
                  </div> */}
                  <div className="course-info">
                    <h4 className="text-center">{course}</h4>
                     <div className="">
                      {/* <p style={{color:'black'}}>Content:<span style={{color:'green',fontSize:'1.2rem',padding:'10px'}}>Recorded Content</span>   </p>
                      <p style={{color:'black'}}><span style={{color:'green',fontSize:'1.2rem',padding:'10px'}}>Certificate On Completion</span>   </p> */}
                     </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;