import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CoursePage = () => {
  const { courseName } = useParams();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`http://localhost:3001/videos/${courseName}`);
        setVideos(response.data);
      } catch (error) {
        console.error("Error fetching videos:", error.message);
      }
    };

    fetchVideos();
  }, [courseName]);

  const playVideo = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div>
      <h1>Course Page: {courseName}</h1>
      {selectedVideo && (
        <div>
          <h2>Selected Video: {selectedVideo}</h2>
          <video controls>
            <source src={`http://localhost:3001/video/${courseName}/${selectedVideo}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <ul>
        {videos.map((video, index) => (
          <li key={index} onClick={() => playVideo(video)}>
            {video}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CoursePage;
