import React from 'react'
import Layout from '../Components/Layout/Layout'
import Telugucourse from '../Components/telugu/Telugucourse'
import Testimonials from '../Components/Testimonials'
export const Telugu = () => {
  return (
    <>
    <Layout title={'Vegrade-Telugu Courses'}>
    <div className="container my-4 mt-5 telugu-courses" style={{ 
  background: `url(images/telugubg.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
//   height: "auto",
  minHeight:"75vh",

 // Optional: Set a specific height for the container
}}>
  <div className="row home  ">
    
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        <h1><span className='learn-span'>Learn Programming in తెలుగు </span> </h1>
        <p>We believe that learning and comprehending material in one's own language will bring out the best in everyone and, without a doubt, make them an expert in the field.</p>
        <button className='Telugu-btn' >
         Explore Now ->
        </button>
     
   
  

    
      </div>
     
    </div>
   
    <div className="col-md-6 order-md-2 order-2 mt-5">
  <iframe
    title="Wistia Video"
    src="https://fast.wistia.net/embed/iframe/vdctir5dqp?autoplay=true"
    frameBorder="0"
    allowFullScreen
    style={{
      width: '100%',
      height: '315px', // Set your desired height
    }}
  />
</div>


    {/* <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
        src="images/home-main.png" // Replace with your image URL
        alt="Sample"
        style={{ height:'30rem',width:'50rem'}}
        className="img-fluid rounded"
      />
    </div> */}
  </div>
</div>
<div className='container mt-5 '>
  <div className='price-box'>
    <h3>
    NEW WEB DEVELOPMENT  (తెలుగు)


    </h3>
    <p>JOIN NEW SIGMA!
   
    </p>
    <p>
          Start your new journey today (New Batch starting from Mar-15)
          
        </p>
        {/* <p><span style={{ textDecoration: 'line-through', marginLeft: '10px',fontSize:'1.6rem',color:"white" }}>₹4000</span>
          <span style={{ color: 'red', marginLeft: '10px',fontSize:'1.8rem',color:"white" }}>₹1999</span> is the course price!
          </p> */}

    <div className='price-btn'>
      <p>Enroll NOW !🤩</p>
    </div>
    
  </div>
</div>

{/* courses in Telugu */}
<div className='container mt-5 learn-cards'>
     <div className='row mt-5'>
     <h3 className='text-center course-heading'>Courses In <span className='teluguspan'>తెలుగు</span> </h3>
     </div>

{/* <LearnCard/> */}
<Telugucourse/>

    </div>


{/* Courses in telugu ends */}

{/* testimonials */}
<div className="container mt-5 ">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Testimonials</span> </p>
          <div className='line text-center' style={{marginLeft:"5rem"}}><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        
     
    {/*  */}
       
      </div>
      <Testimonials/>
    </div>




{/* <div className='container home'>
  <h4>Testimonials</h4>
<div className='row'>
<Testimonials/>
</div>
</div> */}

{/* testimonails ends */}

{/* accordians */}

<div className="container mt-5 ">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Frequently</span>  Asked Questions</p>
          <div className='line'><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        What makes Vegrade Innovations' Telugu courses unique?
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul className="list-unstyled">
          <li>&#8226; Vegrade Innovations stands out for its interactive and immersive approach to Telugu language education. Our courses blend traditional teaching methods with modern technology, providing a dynamic learning experience for students.</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        Are there any prerequisites for enrolling in advanced Telugu courses?
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul className="list-unstyled">
          <li>&#8226; While some advanced courses may have prerequisites, many of our Telugu courses are designed to accommodate learners of varying proficiency levels. Check the course descriptions for specific requirements or contact our support team for guidance.</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        How do I get support if I encounter difficulties during the course?
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul className="list-unstyled">
          <li>&#8226; Vegrade Innovations offers dedicated support to assist you throughout your learning journey. If you encounter any difficulties or have questions, you can reach out to our support team through the online platform, and we'll be happy to help.</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
        Can I receive a certificate upon completion of a Telugu course?
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul className="list-unstyled">
          <li>&#8226; Yes, upon successfully completing a Telugu course at Vegrade Innovations, you will receive a certificate of completion. This certificate can be a valuable recognition of your language proficiency and dedication to learning Telugu.</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
        How flexible are the schedules for accessing course materials?
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul className="list-unstyled">
          <li>&#8226; Our Telugu courses offer flexibility in learning. Once enrolled, you can access course materials at any time that suits your schedule. Whether you prefer to study during the day or night, our online platform allows you to learn at your own pace.</li>
        </ul>
      </div>
    </div>
  </div>
</div>

     
    {/*  */}
       
      </div>
    </div>

{/* accordinas ends */}
    </Layout>
    </>
  )
}
