// LearnCard.js

import React from 'react';
// import { Link } from 'react-router-dom'; // Import Link from React Router

import Layout from '../Layout/Layout';
const projects = [
  {
    id: 1,
    heading: 'Project 1: Web Scraping Application',
    description: 'A web scraping tool built using Python that extracts data from websites and stores it in a database..',
    image: 'images/learnhtml.png',
  },
  {
    id: 2,
    heading: 'Project 2: RESTful API with Flask',
    description: 'Developed a RESTful API using Flask that serves as the backend for a web application, providing data in a structured format.',
    image: 'images/learnhtml.png',
  },
  {
    id: 3,
    heading: 'Project 3: Real-time Chat Application',
    description: 'Built a real-time chat application using Python, Flask, and WebSocket to enable users to communicate instantly.',
    image: 'images/learnhtml.png',
  },
  {
    id: 4,
    heading: 'Project 4: Full Stack E-Commerce Platform',
    description: 'Developed a full-stack e-commerce platform using Python, Django, and React for seamless shopping experiences.',
    image: 'images/learnhtml.png',
  },
  {
    id: 5,
    heading: 'Project 5: Data Analysis Dashboard',
    description: 'Created a data analysis dashboard using Python libraries like Pandas, Matplotlib, and Plotly to visualize and interpret data.',
    image: 'images/learnhtml.png',
  },
  {
    id: 6,
    heading: 'Project 6: Authentication System',
    description: 'Implemented a secure authentication system using Python and Flask, ensuring user data protection and privacy.',
    image: 'images/learnhtml.png',
  },
];


const Awsintern = () => {
  

  return (
    <Layout title={'Vegrade-Aws Course'}>
    <div className="container my-4 mt-5" style={{ 
  background: `url(images/telugubg.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
//   height: "auto",
  minHeight:"75vh",
 
 // Optional: Set a specific height for the container
}}>
  <div className="row home  mt-5 " style={{marginTop:"5rem"}}>
    
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        <h1><span className='learn-span'>Aws Certification 
Training Course</span> </h1>
        <p>Master Cloud Computing skills and take your career to the next level! </p>
        <button className='Telugu-btn' >
         Explore Now ->
        </button>
     
   
  

    
      </div>
     
    </div>
   
    <div className="col-md-6 order-md-2 order-2 mt-5 tlan">
  <iframe
    title="Wistia Video"
    src="https://fast.wistia.net/embed/iframe/vdctir5dqp?autoplay=true"
    frameBorder="0"
    allowFullScreen
    style={{
      width: '100%',
      height: '315px', // Set your desired height
    }}
  />
</div>


    {/* <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
        src="images/home-main.png" // Replace with your image URL
        alt="Sample"
        style={{ height:'30rem',width:'50rem'}}
        className="img-fluid rounded"
      />
    </div> */}
  </div>
</div>

{/* price */}
<div className='container mt-5 '>
  <div className='price-box'>
    <h3>
    NEW AWS TRAINING COURSE 


    </h3>
    <p>JOIN NEW SIGMA!
   
    </p>
    <p>
          Start your new journey today (New Batch starting from Mar-15)
          
        </p>
        {/* <p><span style={{ textDecoration: 'line-through', marginLeft: '10px',fontSize:'1.6rem',color:"white" }}>₹4000</span>
          <span style={{ color: 'red', marginLeft: '10px',fontSize:'1.8rem',color:"white" }}>₹1999</span> is the course price!
          </p> */}

    <div className='price-btn'>
      <p>Enroll NOW !🤩</p>
    </div>
    
  </div>
</div>
{/* price */}
{/* course details */}
<h1 className='text-center'>Course Details</h1>
<div className="telugu-lan-cards-container home">
   
      <div className="telugu-lan-card">
        <h4 style={{fontSize:"1rem",fontWeight:"500",color:"#28CB8A"}}>Community Support</h4>
        <p>24/7</p>
      </div>

      <div className="telugu-lan-card">
        <h4 style={{fontSize:"1rem",fontWeight:"500"}}>Telugu Teaching Language</h4>
        <p>Online</p>
      </div>

      <div className="telugu-lan-card">
        <h4 style={{fontSize:"1rem",fontWeight:"500"}}>Mode Of Teaching</h4>
        <p>200+ Hours Recorded Content</p>
      </div>

      <div className="telugu-lan-card">
        <h4 style={{fontSize:"1rem",fontWeight:"500"}}>Life Time  Content Access</h4>
        <p>Online</p>
      </div>

      <div className="telugu-lan-card">
        <h4 style={{fontSize:"1rem",fontWeight:"500"}}>Mode Of Teaching</h4>
        <p>200+ Hours Recorded Content</p>
      </div>
    </div>
{/* course details */}

{/* course curriculum */}
<div className="container mt-5 ">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Curriculum</span> For Career Developing in Top-Technologies</p>
          <div className='line'><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        <div className="accordion " id="accordionExample">
        <div className="accordion-item">
        <h2 className="accordion-header" id="headingone">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseone" aria-expanded="false" aria-controls="collapseone">
            Accordion Item #2
          </button>
        </h2>
        <div id="collapseone" className="accordion-collapse collapse" aria-labelledby="headingone" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-unstyled">
            <li> &#8226; What is Cloud Computing?</li>
    <li>&#8226;AWS Identity & Access Management</li>
    <li>&#8226;Amazon EC2</li>
    <li>&#8226; Amazon EC2 Instance Storage</li>
    <li>&#8226; Elastic Load Balancing & Auto Scaling Group</li>
    <li>&#8226; Amazon S3</li>
    <li>&#8226; Databases & Analytics</li>
    <li>&#8226; Other Compute Services</li>
    <li>&#8226; Deploying & Managing Infrastructure at Scale</li>
    <li>&#8226; Global Infrastructure</li>
    <li>&#8226; Cloud Integration</li>
    <li>&#8226;Cloud Monitoring</li>
    <li>&#8226;Amazon VPC</li>
    <li>&#8226;Security & Compliance</li>
    <li>&#8226;Machine Learning</li>
    <li>&#8226;Account Management, Billing, & Support</li>
    <li>&#8226;Advanced Identity</li>
    <li>&#8226;Other AWS Services</li>
    <li>&#8226;AWS Architecting & EcoSystem</li>
    <li>&#8226;Exam Preparation</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Accordion Item #2
          </button>
        </h2>
        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-unstyled">
              <li>&#8226; List item 1</li>
              <li>&#8226; List item 2</li>
              <li>&#8226; List item 3</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Accordion Item #3
          </button>
        </h2>
        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <ul className="list-unstyled">
              <li>&#8226; List item 1</li>
              <li>&#8226; List item 2</li>
              <li>&#8226; List item 3</li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
     
    {/*  */}
       
      </div>
    </div>


{/* course curriculum ends */}

{/* projects to be covered here  */}

<div className="container mt-5 ">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Curriculum</span> Projects Developing in Top-Technologies</p>
          <div className='line'><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        
     
    {/*  */}
    <div className="projects-container">
      {projects.map((project) => (
        <div key={project.id} className="project-card">
          <img src={project.image} alt={`Project ${project.id}`} />
          <h4 style={{padding:'10px'}} className='text-center'>{project.heading}</h4>
          <p style={{padding:'20px'}}>{project.description}</p>
        </div>
      ))}
    </div>
      </div>
    </div>

{/* projects covered  */}
{/* what you get  */}
<div className="container mt-5 ">
      <div className="row mt-5">
        <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Tools &</span> Technologies Will Cover </p>
          <div className='line'><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
        <div className="telugu-lan-cards-container ">
   
      <div className="telugu-lan-card">
        <img src='images/learnhtml.png' style={{height:'100px',width:"100px"}}></img>
      </div>

      <div className="telugu-lan-card">
      <img src='images/learnhtml.png' style={{height:'100px',width:"100px"}}></img>
      </div>

      <div className="telugu-lan-card">
      <img src='images/learnhtml.png' style={{height:'100px',width:"100px"}}></img>
      </div>

      <div className="telugu-lan-card">
      <img src='images/learnhtml.png' style={{height:'100px',width:"100px"}}></img>
      </div>

      <div className="telugu-lan-card">
      <img src='images/learnhtml.png' style={{height:'100px',width:"100px"}}></img>
      </div>
    </div>
     
    {/*  */}
       
      </div>
    </div>



{/* what you get ends  */}
    </Layout>
  );
};

export default Awsintern;
