// LearnCard.js

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router


const InternshipCards = () => {
  const courses = [
    {
      id: 1,
      title: 'Frontend Development Internship',
      image: 'images/frontend-intern.png',
      details: 'Details',
      // path: '/html-course', // Define the path for the HTML course
      courseMode:'online',
      limitedSlots:"Limited slots left"

    },
    {
        id: 2,
        title: 'Hands-on AWS Cloud Internship ',
        image: 'images/aws-intern.png',
        details: 'Details about JavaScript Course',
        // path: '/javascript-course', // Define the path for the JavaScript course
      },
    {
      id: 3,
      title: 'Data Science(Machine-Learning) Internship',
      image: 'images/data-intern.png',
      details: 'Details about JavaScript Course',
      // path: '/javascript-course', // Define the path for the JavaScript course
    },
    {
      id: 4,
      title: 'Flutter Development Internship',
      image: 'images/flutter-intern.png',
      details: 'Details about Flask Course',
      // path: '/flask-course', // Define the path for the Flask course
    },
  
    // Add more courses as needed
  ];

  return (
    
    <div className="row mt-5">
    {courses.map((course) => (
      <div key={course.id} className="col-lg-3 col-md-6 col-12">
        <div className="telugu-course-cards">
          <img src={course.image} alt={course.title} className="cardImage" />
          <div className="cardBody">
            <h5>{course.title}</h5>
            <p className="Text">{course.details}</p>
          </div>
          <div className="cardFooter d-flex justify-content-between align-items-end">
            
              <div className="courseMode ">Recorded/Live</div>
              <div className="limitedSlots">Limited Slots</div>
           
           
          </div>
          <div className='enroll-btn'>
          <Link to={course.path} style={{ margin: 0 }}>
              <button className="btn btn-enroll">Enroll Now</button>
            </Link>
            </div>
        </div>
      </div>
    ))}
  </div>
  );
};


export default InternshipCards;
