import React from 'react'
import Layout from '../Components/Layout/Layout'
import LearnCard from '../Components/Layout/LearnCard'
import WebDevCards from '../Components/Layout/webdevelopment/WebDevCards'
import AppDcards from '../Components/Layout/appdevelopment/AppDcards'

import DataScards from '../Components/Layout/datasceince/DataScards'

const Learn = () => {
  return (
    <Layout title={'Vegrade-Learn'}>
           <div className="container my-4  " style={{ 
  background: `url(images/telugubg.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
//   height: "auto",
  minHeight:"75vh"
 // Optional: Set a specific height for the container
}} >
  <div className="row learn">
  
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        <h1><span className='learn-span'>Learn</span> Beyond  the Boundaries </h1>
        <p>From tech to non-tech, select from a wide range of courses to upskill and advance your career!</p>
        <button className='Telugu-btn' >
          Start Learning
        </button>
   
   
  

    
        {/* <h3>Discover endless possibilities with Code Stump. We're more than a platform – we're a launchpad for your ambitions. Explore, learn, and thrive in the world of tech with us</h3> */}
      </div>
     
    </div>
    

    {/* Right side: Image */}
    <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
        src="images/learn.png" // Replace with your image URL
        alt="Sample"
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>
<div className='container learn-container mt-5'>
      <div className='box row learn'>
        <div className='lbox col-md-4'>
          <h3>20+ <br></br> Courses</h3>
        </div>
        <div className='lbox col-md-4'>
          <h3>1000+ <br></br> Active Learners</h3>
        </div>
        <div className='lbox col-md-4'>
          <h3>100+ <br></br> Placed </h3>
        </div>
      </div>
    </div>
    <div className='container mt-5 learn-cards'>
     <div className='row mt-5'>
     <h3 className=''>Web Development</h3>
     </div>

{/* <LearnCard/> */}
<WebDevCards/>
<div className='row mt-5'>
     <h3 className=''>Data Science </h3>

     </div>
     <DataScards/>
     <div className='row mt-5'>
     <h3 className=''>App Devvelopment </h3>
</div>
<AppDcards/>

    </div>

  
 



        </Layout>
  )
   
}

export default Learn