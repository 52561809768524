import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';




const Footer = () => {
  return (
   <>
  
  <MDBFooter bgColor='dark'  className='text-center text-lg-start text-muted m-3'>
      <section style={{color:"white"}} className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <p>Get connected with us on social networks:</p>
        </div>
<div className='icons'>
<a href='your_instagram_url' target='_blank' rel='noopener noreferrer'>
              <MDBIcon fab icon='instagram' className='me-4' />
            </a>
            <a href='your_facebook_url' target='_blank' rel='noopener noreferrer'>
              <MDBIcon fab icon='facebook' className='me-4' />
            </a>
            <a href='your_twitter_url' target='_blank' rel='noopener noreferrer'>
              <MDBIcon fab icon='twitter' className='me-4' />
            </a>
            <a href='your_linkedin_url' target='_blank' rel='noopener noreferrer'>
              <MDBIcon fab icon='linkedin' />
            </a>
</div>
      </section>

      <section className='' style={{color:"white"}}>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='gem' className='me-3' />
               Vegrade Innovations
              </h6>
              <p>
              
Vegrade combines "ve" for vision and "grade" for excellence, symbolizing a commitment to visionary web development solutions. Aligned with education, it emphasizes training and internships for a holistic approach to excellence.</p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Courses On</h6>
              <p>
                <a href='#!' className='text-reset'>
                 Web Development
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Android Development
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Data Science
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                AWS & Devops
                </a>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Home
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Aboutus 
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Courses
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                 Internships
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                Vijayawada,Ap,India
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                info@Vegrade.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> vegradeinnovations@gmail.com
              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: '#000000',color:"white" }}>
      © Copyright . All Rights Reserved since 2024 <></>
        <a className='text-reset fw-bold' href='https:/vegrade.com/'>
       Vegrade Innovations(OPC) private limited
        </a>
      </div>
    </MDBFooter>
   
   </>
  )
}

export default Footer