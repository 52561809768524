import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          {/* <img src="images/logo1.png" alt="Logo" /> */}
          <img src="images/mainlogo.png" alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul className="navbar-nav">
          <li className="nav-item">
              {/* <NavLink className="nav-link" to='/'>
              HomePage
              </NavLink> */}
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/about'>
             About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/learn'>
            Learn
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/intern'>
           Internships
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/telugu'>
           Telugu Courses
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/connect'>
      {/* Connect */}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link " to='/Hire'>
    {/* Hire From Us */}
              </NavLink>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header