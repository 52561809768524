import React, { useState } from "react";
import { auth } from "../../firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem('userUID', auth.currentUser.uid);
      navigate("/dashboard", { state: { userUID: auth.currentUser.uid } });
    } catch (error) {
      console.error("Error logging in:", error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="left-panel">
        <img src="sample-image.jpg" alt="Sample" />
      </div>
      <div className="right-panel">
        <h2>Login</h2>
        <div className="input-wrapper">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email-input"
            required
          />
          <label htmlFor="email-input" className={email ? "active" : ""}>
            Email
          </label>
        </div>
        <div className="input-wrapper">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password-input"
            required
          />
          <label htmlFor="password-input" className={password ? "active" : ""}>
            Password
          </label>
        </div>
        <button className="login_btn" onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default Login;