// LearnCard.js

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router


const Telugucourse = () => {
  const courses = [
    {
      id: 1,
      title: 'Programming Languages Fundamentals',
      image: 'images/learnhtml.png',
      details: 'This course is going to be begin Soon ',
      // path: '/html-course', // Define the path for the HTML course
      courseMode:'online',
      limitedSlots:"Limited slots left",
      btn:"Cooming Soon"

    },
    {
        id: 2,
        title: 'Web Development in Telugu',
        image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
        details: 'New  Batch Starting From March-15',
        // path: '/javascript-course', // Define the path for the JavaScript course
      btn:"Enroll Now"
      },
    {
      id: 3,
      title: 'AWS & Devops in Telugu',
      image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
      details: 'New  Batch Starting From March-15',
      // path: '/javascript-course',
      btn:"Enroll Now" // Define the path for the JavaScript course
    },
    {
      id: 4,
      title: 'Data Base Management System',
      image: 'https://d8it4huxumps7.cloudfront.net/uploads/images/courses/3789/banners/651d40681d0ec_Aptitude-03.jpg?d=800x420',
      details: 'This course is going to be begin Soon',
      // path: '/flask-course', // Define the path for the Flask course
      btn:"Cooming Soon"
    },
  
    // Add more courses as needed
  ];

  return (
    // <div className="row mt-5 ">
    //   {courses.map((course) => (
    //     <div key={course.id} className="col-lg-3 col-md-6 col-12">
    //       <div className="telugu-course-cards">
    //         <img src={course.image} alt={course.title} className="cardImage" />
    //         <div className="cardBody">
    //           <h5>{course.title}</h5>
    //           <p className="Text">{course.details}</p>
    //         </div>
    //         <div className="cardFooter">
    //           {/* Use Link to navigate to the specified path when clicking on the button */}
    //           <p className="viewButton">
    //             View Course
    //           </p>
    //           <Link to={course.path}  style={{ margin: 0 }}>
               
    //           <span className="arrowSymbol">&#62;</span>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>
    <div className="row mt-5">
    {courses.map((course) => (
      <div key={course.id} className="col-lg-3 col-md-6 col-12">
        <div className="telugu-course-cards">
          <img src={course.image} alt={course.title} className="cardImage" />
          <div className="cardBody">
            <h5>{course.title}</h5>
            <p className="Text">{course.details}</p>
          </div>
          <div className="cardFooter d-flex justify-content-between align-items-end">
            
              <div className="courseMode ">Recorded/Live</div>
              <div className="limitedSlots">Limited Slots </div>
           
           
          </div>
          <div className='enroll-btn'>
          <Link to={course.path} style={{ margin: 0 }}>
              <button className="btn btn-enroll">{course.btn}</button>
            </Link>
            </div>
        </div>
      </div>
    ))}
  </div>
  );
};

export default Telugucourse;
