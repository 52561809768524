import React from 'react'
import Header from './Header'
import Footer from './Footer'
import {Helmet} from 'react-helmet'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Layout = ({children,title,description,keywords,author}) => {
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords}/>
                <meta name="author" content={author}/>
                <title>{title}</title>
               
            </Helmet>
    <Header />
     <main style={{minHeight:'80vh'}}>
     <ToastContainer />
      
      {children}</main>
    <Footer/>
    </>
  
  )
}
Layout.defaultProps = {
    title: 'Vegrade',
    description: 'Explore our software services, internship opportunities, and training programs. Join us for innovative solutions and learning experiences.',
    keywords: 'software services, internships, training, technology, coding, development',
    author: 'Vegrade innovations',
  };
  

export default Layout