
import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes,Route,Navigate } from 'react-router-dom';
import HomePage from './Pages/HomePage';
// import ContactUs from './Pages/ContactUs';
// import PageNotFound from './Pages/PageNotFound';
import AboutUs from './Pages/AboutUs';
import Learn from './Pages/Learn';
// import HireFrom from './Pages/HireFrom';
import Internship from './Pages/Internship';
import { Telugu } from './Pages/Telugu';
import Telugucourse from './Components/telugu/Telugucourse';
import TLan from './Components/telugu/TLan';

// import { ConnectPage } from './Pages/ConnectPage';

// import Sample from './Pages/Sample';
// import LearnCard from './Components/Layout/LearnCard';
// 
import Register from './Components/auth/authRegister';

// notifications
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// internships
import Awsintern from './Components/internships/Awsintern';
// sub routes endds

// 
// testin routees
import MainDashboard from './Components/dashbord-access/CoursePage.js';

// testing
// 3333333
import CoursePage from './Components/dashbord-access/Courses.js';
import Course from './Components/dashbord-access/Courses.js'
import Dashboard  from './Components/dashbord-access/dashboard';
import Login from './Components/auth/auth_login.js';
import { auth } from './firebase-config';
// ######

const PrivateRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    // You can show a loading spinner or another loading indicator here
    return null;
  }

  return user ? element : <Navigate to="/" />;
};

// 


function App() {
  return (
  <>
  <Routes>
    <Route path='/' element={<HomePage/>} />
    <Route path='/About' element={<AboutUs/>}/>
    {/* <Route path='/Contact' element={<ContactUs/>}/> */}
    {/* <Route path='/Hire' element={<HireFrom/>}/> */}
    <Route path='/Learn' element={<Learn/>}/>
    <Route path='/intern' element={<Internship/>}/>


    
    {/* <Route path='/Connect' element={<ConnectPage/>}/> */}
    {/* <Route path='*' element={<PageNotFound/>}/> */}

    {/* <Route path='/sa' element={<Sample/>}/> */}

    {/* sub routes */}
    {/* <Route path='/L' element={<LearnCard/>}/> */}

    {/* register and login */}
    <Route path='/reg' element={<Register/>}/>

    {/* telugu routing */}
  
    <Route path='/telugu' element={<Telugu/>}/>
    <Route path='/telugucourse' element={<Telugucourse/>}/>
    <Route path='/tlan' element={<TLan/>}/>

{/* internships and courses */}
<Route path='/aws' element={<Awsintern/>}/>

{/* dashboards */}
<Route path='/md' element={<MainDashboard/>}/>


{/* 
// user access */}
<Route path="/login" element={<Login />} />
      <Route path="/course/:courseName" element={<CoursePage />} />
      <Route
        path="/dashboard"
        element={<PrivateRoute element={<Dashboard />} />}
      />
       <Route path="/courses/:courseName" element={<PrivateRoute element={<Course />} />} />

{/* ## */}

  </Routes>
  </>
  
   
  );
}

export default App;
