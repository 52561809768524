import React, { useEffect, useState } from 'react';

import Layout from '../Components/Layout/Layout'
const AboutUs = () => {
  
  
  
  return (
    <Layout title={'Vegrade-Aboutus'}>
       <div className="container my-4 ">
  <div className="row learn">
  
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        <h1><span className='learn-span'> Vegrade Chronicles</span>     </h1>
        <p>Step into the heartbeat of Vegrade Innovations, where inventive minds converge to redefine possibilities. Our story unfolds with passion, purpose, and a commitment to crafting a future illuminated by innovation.</p>
        <button className='Telugu-btn' >
          Start Learning
        </button>
  
   
  

    
        {/* <h3>Discover endless possibilities with Code Stump. We're more than a platform – we're a launchpad for your ambitions. Explore, learn, and thrive in the world of tech with us</h3> */}
      </div>
     
    </div>
    

    {/* Right side: Image */}
    <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
        src="images/about.png" // Replace with your image URL
        alt="Sample"
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>

{/*  why we  */}

<div className='container  mt-5'>
  {/* <p className='text-center mt-5' style={{fontSize:'.9rem',fontWeight:'500'}}>Why Us?</p> */}
 <div className='why-us'>
 <div className='row d-none d-lg-block mt-5 why-us-left'>
   
 <img src='images/why.png' />
   </div>
   <div className='row mt-5 why-us-right'>
    <h3 className='text-center' style={{fontSize:'3rem',color:"#0067e0"}}>WHY-US ?</h3>
    <div className='why-right-content'>
     <div className='why-right-content-left one'>
    <img src='images/globe.png'></img>
     </div>
     <div className='why-right-content-left-right'>
      <h3 style={{fontStyle:"rubik"}}>Plethora of Opportunities
</h3>
<p>Apply for Internships, Projects and Jobs with our partnered companies.</p>
      </div>
    </div>
    <div className='why-right-content'>
     <div className='why-right-content-left two'>
     <img src='images/connection.png'></img>
     </div>
     <div className='why-right-content-left-right'>
      <h3 style={{fontStyle:"rubik"}}>Technology Focussed
</h3>
<p>Learn about emerging technologies by working on Live Industry projects.</p>
      </div>
    </div>
    <div className='why-right-content'>
     <div className='why-right-content-left three'>
     <img src='images/users.png'></img>
     </div>
     <div className='why-right-content-left-right'>
      <h3 style={{fontStyle:"Medium 500"}}>
Industry Experts
</h3>
<p>Get mentored by Industry Experts with many years of ears of    experience.</p>
      </div>
    </div>
    </div>
 </div>
</div>
{/*  why we ends */}
{/* recoginized by */}
<div className='container about-content mt-5'>
  <p className='text-center mt-5' style={{fontSize:'.9rem',fontWeight:'500'}}>LEARN THE BEST FROM THE ALUMNI OF</p>
  <div className='row mt-5'>
   <img src='images/mentor-community.png'>

   </img>
  </div>
</div>
<div className='container about-content mt-5'>
  <p className='text-center mt-5' style={{fontSize:'.9rem',fontWeight:'500'}}> RECOGNISED BY</p>
  <div className='row mt-5'>
   <img src='images/recognitions.png'>

   </img>
  </div>
</div>

{/* recoginnized by end */}
{/* structure */}
<div className="container about-content mt-5">
  <h3 className='text-center ' style={{color:'#233e4b',fontWeight:'700'}}>Unlock Lucrative Paths at Vegrade: Elevate Your Career in <br></br> <span>4.0 Technology</span></h3>
  <div className="row mt-5">
    {/* Flow 1 */}
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/code.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Code Foundations<br></br> Learn Syntax  </p>
    
      </div>
      <div >

    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/tech.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Tech Mastery<br></br>Master Technologies  </p>
    
      </div>
      <div >

    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/portfolio.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Digital Showcase<br></br>Showcase Skills  </p>
    
      </div>
      <div >
      
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/innovation.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Innovation Focus<br></br>Explore Innovations </p>
    
      </div>
      <div >
    
    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/project.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Impactful Projects<br></br>Develop Projects </p>
    
      </div>
      <div >

    </div>
    </div>
    <div className="col-md-2 component mt-5">
      {/* Your component content for Flow 1 */}
     <div className='ctext'>
       <img src='images/support.png'></img>
      <p style={{fontFamily:'Montserrat',fontSize:'.9rem'}}>Tech Networking<br></br>Seek Guidance</p>
    
      </div>
      <div >
      {/* <span>&#x2192;</span> */}
    </div>
    </div>

   
  </div>
</div>

{/* structure */}
<div className='container about-content mt-5'>
  <div className='row'>
    <div className='col'>
    <img style={{height:'50px',width:'50px'}} className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

    </div>
    <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

      </div>
      <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

    </div>
    <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

      </div>
      <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

    </div>
    <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

      </div>
      <div className='col'>
    <img style={{height:'50px',width:'50px'}}  className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />

    </div>
    
  </div>
</div>

{/* our alumini work at */}
<div className='container about-content mt-5'>
  <h3>Your Future - Top companies our alumni work at</h3>
  <div className='row about-al mt-5'>
    <div className='col-md-2 about-alumini'>
        <div className='card'>
            <img className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' alt='Card image cap' />
        </div>
    </div>
    <div className='col-md-2 about-alumini'>
        <div className='card'>
            <img className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/11/Accenture-2.png' alt='Card image cap' />
        </div>
    </div>
    <div className='col-md-2 about-alumini'>
        <div className='card'>
            <img className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/KPMG-1.png' alt='Card image cap' />
        </div>
    </div>
    <div className='col-md-2 about-alumini'>
        <div className='card'>
            <img className='card-img-top' src='https://www.simplilearn.com/wp-content/uploads/2022/08/Thumbnail-logo.png' alt='Card image cap' />
        </div>
    </div>
</div>

  <div className='row about-al'>
    <div className='col-md-3 about-alumini'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Amazon-2.png'></img>

    </div>
    <div className='col-md-3 about-alumini'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-178.png'></img>

    </div>
    <div className='col-md-3 about-alumini'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-180.png'></img>

    </div>
    <div className='col-md-3 about-alumini'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Capgemini.png'></img>

    </div>
  </div>
</div>
{/* stories from other categories */}
<div className='container about-content mt-5'>
  <h3>Stories from other categories</h3>
  <div className='row about-al mt-5'>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Group.png' className='img-fluid'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/11/Accenture-2.png' className='img-fluid'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/KPMG-1.png' className='img-fluid'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Thumbnail-logo.png' className='img-fluid'></img>

    </div>
  </div>
  <div className='row about-al'>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Amazon-2.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-178.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-180.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Capgemini.png'></img>

    </div>
  </div>
  <div className='row about-al'>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Amazon-2.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-178.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/image-180.png'></img>

    </div>
    <div className='col-md-3 about-tech'>
    <img src='https://www.simplilearn.com/wp-content/uploads/2022/08/Capgemini.png'></img>

    </div>
  </div>
</div>
{/*  */}
<div className='container mt-5 home'>
    <div className='row '>
  <div className='col-6'>
    <img src='images/global.png'></img>
    <h1  style={{fontWeight:'700',color:'#233e4b'}}>Trusted by Thousands to Become IT Professionals</h1>
    <p style={{color:'#233e4b',fontWeight:'600'}}>
      making Education affordable and accessible across the Globe
    </p>
    <div className='row'>
   
   <div className='col-md-12'>
    <div className='about-count'>
      <img src='https://nxtwave-website-media-files.s3.ap-south-1.amazonaws.com/ccbp-website/Reviews/company-icon.png'>

      </img>
      <div className='about-text'>
        <h3>
1500+ Students</h3>
<p>
Satisfied across the world
</p>
      </div>

    </div>

   </div>
  
   <div className='col-md-12 mt-2'>
   <div className='about-count'>
      <img src='https://nxtwave-website-media-files.s3.ap-south-1.amazonaws.com/ccbp-website/Reviews/company-icon.png'>

      </img>
      <div>
        <h3>
1000+</h3>
<p>
Globaly certified
</p>
      </div>

    </div>
    
    </div>
    
    <p style={{marginLeft:'1rem'}}>  Next batch starts on march 15th</p>
    <button className='about-btn'>Book Your Slot </button>
  
    </div>
  </div>
  <div className='col-6'>
    <img src='https://tse1.mm.bing.net/th?id=OIP.U4Q9nMxTz9bPqkVncRqS2wHaFj&pid=Api&P=0&h=220'></img>
  </div>
</div>

    </div>
{/*  */}
    </Layout>
  )
}

export default AboutUs