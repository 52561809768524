import React from 'react'
import Layout from '../Components/Layout/Layout'
import { Link } from 'react-router-dom';
import InternshipCards from '../Components/internships/InternshipCards';
const Internship = () => {
  return (
<Layout title={'Vegrade-intern'}>
<div className="container mt-4">
  <div className="row learn">
  
    <div className="col-md-6 order-md-1 order-1">
      <div className="text-left">
        <h1><span className='learn-span'>Empower</span> . Innovate. Succeed. <br></br> Intern With Us  </h1>
        <p>Fuel your ambitions with hands-on experiences, mentorship, and a dynamic environment. Elevate your skills and shape a future of innovation.</p>
        <button className='Telugu-btn ' >
          Start Learning
        </button>
     
        {/* <h3>Discover endless possibilities with Code Stump. We're more than a platform – we're a launchpad for your ambitions. Explore, learn, and thrive in the world of tech with us</h3> */}
      </div>
     
    </div>
    

    {/* Right side: Image */}
    <div className="col-md-6 order-md-2 order-2 mt-5 ">
      <img
        src="images/intern-home.png" // Replace with your image URL
        alt="Sample"
        className="img-fluid rounded"
      />
    </div>
  </div>
</div>
<div className='container mt-5'>
  <h3 className='text-center mt-5'>Trending Domains</h3>
  <div className='row top-domains mt-5'>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Web Development</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Mobile App Development</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Data Base Technologies</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>UI/UX Designing</p>
        </div>
    </div>
    

   
</div>
<div className='row top-domains-two  ' style={{marginLeft:'10rem'}}>
<div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>AWS & Devops</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Data Science</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
        <div>
            <img src='images/skills.png' alt='Skills'></img>
        </div>
        <div>
            <p>Cyber Security</p>
        </div>
    </div>
    <div className='col-md-3 trending-domains'>
       
        <div>
            <p>Explore more</p>
        </div>
        <div className='arrow-symbol'>
    <span className='arrow-symbol' >&#8593;</span>
</div>

    </div>
    </div>

</div>
<div className='container learn d-none d-sm-block mt-5' style={{ marginBottom: '20px' }}>
    <div className='row intern-cards'>
      <div className='col-12 col-md-6 intern-card' style={{ 
  background: `url(images/aws-cloud.png)`,
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
//   height: "auto",
  // minHeight:"75vh"
 // Optional: Set a specific height for the container
}}>
        <div className='card-content'>
          <div className='left-content'>
          <h3>Industrial Internship On <br />AWS & Devops</h3>
          <p style={{fontSize:"1.1rem",fontWeight:"500"}}>JOIN NEW SIGMA!
   
   </p>
   <p style={{fontSize:"1.1rem",fontWeight:"400"}}>
         Start your new journey today <br></br> ( starting from Mar-15)
         
       </p>
            <Link to='/' className='start-now-button'>
              Start Now
              <span className='arrow-symbol'>&#8593;</span>
            </Link>
          </div>
        </div>
      </div>

      <div className='col-12 col-md-6 intern-card'style={{ 
  background: `url(images/webintern.png)`,
  backgroundSize: "100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
//   height: "auto",
  // minHeight:"75vh"
 // Optional: Set a specific height for the container
}}>
        <div className='card-content'>
          <div className='left-content'>
            <h4>Industrial Internship On <br /> Web Development</h4>
            <p style={{fontSize:"1.1rem",fontWeight:"500"}}>JOIN NEW SIGMA!
   
    </p>
    <p style={{fontSize:"1.1rem",fontWeight:"400"}}>
          Start your new journey today<br></br> ( starting from Mar-15)
          
        </p>
            <Link to='/' className='start-now-button'>
            Enroll Now
              <span className='arrow-symbol'>&#8593;</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* what you do */}
  <div className='container learn-cards mt-5' >
   
        <h3 className='text-center' style={{fontSize:'2.1rem',color:"rgb(10, 10, 89)"}}> What you Learn In Vegrade's Internship's</h3>
  
    <div className='row intern-flow mt-5'>
  <div className='venky col-6 col-md-3'>
  <div className='col-6 col-md-3 intern-circle intern-circle1 position-relative'>
        {/* Logo */}
        <img src="https://internship.ineuron.ai/images/internship-step-3.svg" alt="Logo" className="logo" />

        {/* Rounded badge with count */}
        <div className="badge badge-primary rounded-circle badge-count position-absolute top-0 start-0">1</div>
       
      </div>
      <div className="circle-content">
          <h4>Register with us</h4>
          <p>Register yourself to build together.</p>
         
        </div>
  </div>
  <div className='venky col-6 col-md-3'>
  <div className='col-6 col-md-3 intern-circle intern-circle2 position-relative'>
        {/* Logo */}
        <img src="https://internship.ineuron.ai/images/internship-step-3.svg" alt="Logo" className="logo" />

        {/* Rounded badge with count */}
        <div className="badge badge-primary rounded-circle badge-count position-absolute top-0 start-0">2</div>
       
      </div>
      <div className="circle-content">
          <h4>Personalized Mentorship</h4>
          <p>Join for a bespoke learning experience</p>
         
        </div>
  </div>
  <div className='venky col-6 col-md-3'>
  <div className='col-6 col-md-3 intern-circle intern-circle3 position-relative'>
        {/* Logo */}
        <img src="https://internship.ineuron.ai/images/internship-step-3.svg" alt="Logo" className="logo" />

        {/* Rounded badge with count */}
        <div className="badge badge-primary rounded-circle badge-count position-absolute top-0 start-0">3</div>
       
      </div>
      <div className="circle-content">
      <h4>Complete the Training</h4>
<p>Elevate your skills to mastery.</p>
         
        </div>
  </div>
  <div className='venky col-6 col-md-3'>
  <div className='col-6 col-md-3 intern-circle intern-circle4 position-relative'>
        {/* Logo */}
        <img src="https://internship.ineuron.ai/images/internship-step-3.svg" alt="Logo" className="logo" />

        {/* Rounded badge with count */}
        <div className="badge badge-primary rounded-circle badge-count position-absolute top-0 start-0">4</div>
       
      </div>
      <div className="circle-content">
      <h4>Job Ready Assistance</h4>
<p>Prepare for success with confidence.</p>
         
        </div>
  </div>
    </div>
  </div>




  {/* what you do ends */}

  {/* Intershiip benefits */}
  <div className='container  learn-cards  mt-5'>
  <div className='text mt-5 '>
          <p className='col-md-12 text-center'> <span>Our Number's</span>  </p>
          <div className='line' style={{marginLeft:"12rem"}}><img src='https://h6y4n4y7.rocketcdn.me/wp-content/uploads/2023/05/heading-separator.png'></img></div>
        </div>
{/* <h2 className='text-center'> Our Number's</h2> */}
    <div className='row intern-count mt-5 text-center'>
      <div className='col-md-2 count'>
<h6> <span className='interncount'>  700+ </span> <br/>
Active Users</h6>
      </div>
      <div className='col-md-2 count'>
      <h6> <span className='interncount'>  100+ </span> <br/>
Assessments</h6>
      </div>
      <div className='col-md-2 count'>
      <h6> <span className='interncount'> 100+ </span> <br/>
Opportunities</h6>
      </div>
      

      <div className='col-md-2 count'>
<h6> <span className='interncount'>  20+ </span>  <br/>
Brands trust us</h6>
      </div>
      <div className='col-md-2 count'>
      <h6> <span className='interncount'> 10+ </span>  <br/>
Organisations</h6>
      </div>
      <div className='col-md-2 count'>
      <h6> <span className='interncount'>  5+</span>  <br/>
Countries</h6>
      </div>
      
    </div>
  
  </div>
  <div className='container trending-internship mt-5'>
    <p className='text-center' style={{fontSize:'1.6rem',fontWeight:'700'}}>Top Trending Internship's</p>
    <InternshipCards/>
  </div>

  {/* internship benefits ends */}
</Layout>
  )
}

export default Internship